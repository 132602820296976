import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import io from 'socket.io-client';

function BettingPage({ userInfo }) {
  const [cryptoPrices, setCryptoPrices] = useState({ bitcoin: 0, ethereum: 0 });
  const [error, setError] = useState('');
  const [betAmount, setBetAmount] = useState(0.1);
  const [betType, setBetType] = useState('long');
  const [betHistory, setBetHistory] = useState([]);
  const [balance, setBalance] = useState(100);
  const [timer, setTimer] = useState(60);
  const [selectedAsset, setSelectedAsset] = useState('bitcoin');
  const [pendingBets, setPendingBets] = useState([]);

  // Connect to the server via Socket.IO for real-time prices
  const socket = io('https://betbunny.xyz:5000');

  useEffect(() => {
    console.log('Attempting to connect to the server for real-time prices...');
    // Listen for updates from the server
    socket.on('crypto-prices', (prices) => {
      console.log('Received crypto prices:', prices); // Log the prices received
      setCryptoPrices(prices);
    });

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
      setError('Failed to connect to server.');
    });

    return () => socket.off('crypto-prices');
  }, []);

  // Place a bet
  const placeBet = async () => {
    try {
      const response = await fetch('/api/placeBet', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userInfo?.telegramId, // Ensure userId is passed correctly
          betType,
          amount: betAmount,
          asset: selectedAsset,
          initialPrice: cryptoPrices[selectedAsset.toLowerCase()],
        }),
      });

      if (!response.ok) throw new Error('Failed to place bet');

      const data = await response.json();
      setBetHistory([...betHistory, data.bet]);
      setPendingBets([...pendingBets, data.bet]);
    } catch (error) {
      setError('Error: ' + error.message);
    }
  };

  // Resolve bets when timer hits zero
  const resolveBets = () => {
    const updatedHistory = pendingBets.map((bet) => {
      const finalPrice = cryptoPrices[bet.asset.toLowerCase()];
      const result =
        (bet.betType === 'long' && finalPrice > bet.initialPrice) ||
        (bet.betType === 'short' && finalPrice < bet.initialPrice)
          ? 'Win'
          : 'Lose';

      const updatedBet = { ...bet, result };
      if (result === 'Win') {
        setBalance((prev) => prev + parseFloat(bet.amount));
      } else {
        setBalance((prev) => prev - parseFloat(bet.amount));
      }

      return updatedBet;
    });

    setBetHistory(updatedHistory);
    setPendingBets([]);
    localStorage.setItem('betHistory', JSON.stringify(updatedHistory));
    localStorage.setItem('balance', balance);
  };

  // Timer countdown
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    if (timer === 0) {
      clearInterval(interval);
      resolveBets();
      setTimer(60);
    }

    return () => clearInterval(interval);
  }, [timer]);

  // Adjust chart data based on the selected asset
  const chartData = {
    labels: [selectedAsset.toUpperCase()],  // Display only the selected asset
    datasets: [
      {
        label: `Price (${selectedAsset.toUpperCase()} / USDT)`,
        data: [cryptoPrices[selectedAsset.toLowerCase()]],
        backgroundColor: ['rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <h1>Betting Page</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <h2>Crypto Prices</h2>
      <p>Bitcoin: {cryptoPrices.bitcoin || 'Loading...'}</p>
      <p>Ethereum: {cryptoPrices.ethereum || 'Loading...'}</p>

      <Line data={chartData} />

      <h2>Timer: {timer} seconds</h2>

      <h2>Select Asset</h2>
      <select value={selectedAsset} onChange={(e) => setSelectedAsset(e.target.value)}>
        <option value="bitcoin">Bitcoin</option>
        <option value="ethereum">Ethereum</option>
      </select>

      <h2>Place Your Bet</h2>
      <div>
        <input
          type="number"
          value={betAmount}
          onChange={(e) => setBetAmount(e.target.value)}
        />
        <button onClick={() => setBetType('long')} disabled={timer === 0}>Long</button>
        <button onClick={() => setBetType('short')} disabled={timer === 0}>Short</button>
        <button onClick={placeBet} disabled={timer === 0}>Place Bet</button>
      </div>

      <h2>Balance: {balance}</h2>

      <h2>Bet History</h2>
      {betHistory.length === 0 ? (
        <p>No bets placed yet</p>
      ) : (
        <ul>
          {betHistory.map((bet, index) => (
            <li key={index}>
              {bet.betType} bet of {bet.amount} {bet.asset} (Result: {bet.result})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default BettingPage;
