import React, { useState, useEffect } from 'react';
import BettingPage from './BettingPage';

function App() {
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', telegramId: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready(); // Notify Telegram that the WebApp is ready

      const user = webApp.initDataUnsafe?.user;
      if (user && user.id) {
        setUserInfo({
          firstName: user.first_name || 'No First Name',
          lastName: user.last_name || 'No Last Name',
          telegramId: user.id,
        });
        setIsLoggedIn(true);
      } else {
        setError('Please open this app within Telegram to login.');
      }
    } else {
      setError('Telegram WebApp object not available.');
    }
  }, []);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <div>
      {isLoggedIn ? (
        <BettingPage userInfo={userInfo} />
      ) : (
        <p>Loading... Please wait for the Telegram login...</p>
      )}
    </div>
  );
}

export default App;
